$primary: #552ac2;
$primary-light: #b7a4e2;
$black: #1c1517;
$mobile-width: 769px;
$custom-width: 1024px;

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

@mixin screen-max($max) {
  @media (max-width: ($max - 1)) {
    @content;
  }
}

@keyframes slideIn {
  from {
    left: -300px;
  }
  to {
    left: 0;
  }
}

* {
  margin: unset;
  padding: unset;
  box-sizing: border-box;
  line-height: 1.2;
}

input {
  font-family: inherit;
}

html {
  height: 100%;
  min-width: fit-content;
}

body {
  font-size: 17px;
  font-family: "Fira Sans", sans-serif;
  background-image: url("../images/pattern.png");
}

.header {
  width: 100%;
  background-color: white;
  position: relative;
  z-index: 1;

  @include screen-max($custom-width) {
    background: none;
    position: fixed;
    padding: 1em;
  }
}

.navbar--hamburger {
  display: none;

  @include screen-max($custom-width) {
    display: block;
  }

  .navbar__button {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: unset;
    background-color: rgba($black, 0.9);
    color: #d6caf1;
    position: absolute;
    right: 1.5em;
    top: 1.5em;

    & > svg {
      font-size: 1.6em;
    }

    &:hover,
    &:active {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .navbar__logo {
    width: 80px;
    height: 80px;
    transition-property: opacity, transform;
    transition-duration: 0.3s;

    &:hover,
    &:active {
      opacity: 0.9;
      transform: scale(1.1);
      cursor: pointer;
    }

    &__image {
      width: 100%;
    }
  }

  .navbar__list {
    position: fixed;
    background-color: rgba($black, 0.9);
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    overflow: hidden;
    max-width: 260px;
    padding-top: calc(90px + 2em);

    &--show {
      width: 100%;
      animation: slideIn 0.3s;
    }
  }

  .navbar__item {
    list-style: none;
  }

  .navbar__link {
    display: block;
    padding: 1.5em 2em;
    color: white;

    &--active,
    &:hover,
    &:active {
      cursor: pointer;
      background: $black;
    }
  }
}

.navbar {
  max-width: $custom-width;
  height: 200px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;

  @include screen-max($custom-width) {
    display: none;
  }

  .navbar__list {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .navbar__logo {
    margin-right: 20px;
    transition-property: opacity, transform;
    transition-duration: 0.3s;
    backface-visibility: hidden;
    transform: translateZ(0) perspective(1px);
  }

  .navbar__logo:hover,
  .navbar__logo:active {
    opacity: 0.8;
    transform: translateZ(0) scale(1.05);
  }

  .navbar__logo__image {
    max-width: 120px;
  }

  .navbar__item {
    text-decoration: none;
    list-style: none;
    text-align: center;
    margin-right: 2em;
  }

  .navbar__item:not(.navbar__logo) {
    width: 100px;
  }

  .navbar__link {
    display: block;
    color: #9695b3;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.5em 0;

    &:hover {
      cursor: pointer;
    }
  }

  .navbar__link:not(.navbar__logo):hover,
  .navbar__item--active .navbar__link {
    border-bottom: 3px solid $primary;
    margin-bottom: -3px;
  }
}

.navbar__socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: absolute;
  right: 0;

  .navbar__socials__item {
    list-style: none;
    margin-bottom: 1em;
  }

  .navbar__socials__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50px;
    color: white;
    transition: opacity 0.2s;
  }

  .navbar__socials__link:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  .navbar__socials__link--instagram {
    background-color: #e4405f;
    font-size: 1.1em;
    width: 40px;
    height: 40px;
    margin-right: 1em;
  }

  .navbar__socials__link--facebook {
    background-color: #4267b2;
    width: 40px;
    height: 40px;
  }

  .lang-select__wrapper {
    display: flex;
    flex-grow: 100;
    flex-basis: 100%;
    justify-content: flex-end;
  }

  .lang-select__button {
    font-size: 17px;
    border: none;
    outline: none;
    color: black;
    padding: 0.5em;
    background-color: inherit;
    font-family: inherit;
    margin: 0;

    &:hover {
      cursor: default;
    }
  }

  .dropdown__content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .lang-select__dropdown .dropdown__content label {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;

    &:hover {
      cursor: pointer;
      background: #eee;
    }

    & > * {
      display: none;
    }
  }

  .lang-select__dropdown:hover .dropdown__content,
  .lang-select__dropdown:active .dropdown__content {
    display: block;
  }
}

.navbar--hamburger .navbar__socials {
  position: relative;
  justify-content: unset;
  margin: 0 1em;
  margin-top: 2em;

  .lang-select__wrapper {
    flex-basis: auto;
    justify-content: flex-end;
  }

  .lang-select__button {
    color: #fff;
  }
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.homepage {
  background-image: url("../images/banner.jpg");
  background-position: center;
  background-size: cover;
  height: calc(100vh - 200px);
  width: 100%;
  position: relative;

  @include screen-max($custom-width) {
    height: 100vh;
    text-align: center;
  }
}

.homepage__container {
  display: flex;
  width: $custom-width;
  height: 100%;
  align-items: center;
  margin: 0 auto;

  @include screen-max($custom-width) {
    width: 100%;
  }
}

.homepage__content {
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: 50%;
  text-shadow: 0 1px 10px $black;

  @include screen-max($custom-width) {
    margin: 0 auto;
  }

  @include screen-max($mobile-width) {
    max-width: 100%;
    padding: 2em;
  }
}

.homepage__title {
  font-size: 3em;
  font-weight: 300;
  margin-bottom: 0.5em;

  @include screen-max($mobile-width) {
    font-size: 2.5em;
    margin-bottom: 1em;
  }
}

.homepage__text {
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.4;

  @include screen-max($mobile-width) {
    font-size: 1em;
  }
}

.homepage__button {
  max-width: 320px;
  text-align: center;
  display: block;
  background-image: linear-gradient(
    to right,
    $primary 0%,
    #531180 51%,
    $primary 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  margin-top: 2em;
  padding: 1em 1.4em;
  color: #d8dce7;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.3em;
  border-radius: 50px;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.5);

  &:hover,
  &:active {
    background-position: right center; /* change the direction of the change here */
    cursor: pointer;
  }

  @include screen-max($custom-width) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5em;
  }
}
.prices {
  padding-top: 5em;
  padding-bottom: 5em;

  @include screen-max($custom-width) {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.prices__container {
  width: $custom-width;
  margin: 0 auto;

  @include screen-max($custom-width) {
    width: 100%;
  }
}

.prices__title {
  font-size: 1.8em;
  color: $primary;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1em;
}

.prices__card {
  box-shadow: 0px 15px 80px rgba(34, 35, 58, 0.2);
  border-radius: 25px;
  background: #fff;
  position: relative;
  margin: 0 auto;
  padding: 2em;
  display: flex;

  @include screen-max($mobile-width) {
    flex-direction: column;
  }
}

.prices__card--dog {
  margin-bottom: 3em;
}

.prices__image {
  max-width: 350px;
  max-height: 500px;
  border-radius: 20px;
  box-shadow: 4px 13px 30px 1px rgba(157, 56, 252, 0.2);
  overflow: hidden;

  @include screen-max($mobile-width) {
    max-height: 300px;
    margin: 0 auto;
  }

  @include screen-max(420px) {
    max-height: 250px;
    margin: 0 auto;
  }
}

.prices__image img {
  height: auto;
}

.prices__image--dog {
  transform: translateY(-50px);
  width: 130%;

  @include screen-max($mobile-width) {
    transform: translateY(-90px);
    width: 125%;
  }

  @include screen-max(420px) {
    transform: translateY(-40px);
  }
}

.prices__image--cat {
  transform: translate(-85px, -90px);
  width: 130%;

  @include screen-max($mobile-width) {
    transform: translate(-75px, -170px);
  }

  @include screen-max(420px) {
    transform: translate(-50px, -100px);
  }
}

.prices__card--cat .prices__image {
  order: 1;

  @include screen-max($mobile-width) {
    order: unset;
  }
}

.prices__content {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-between;
  margin-top: 1em;
  gap: 1.5em;
}

.prices__card--dog .prices__content {
  padding-left: 2em;

  @include screen-max($mobile-width) {
    padding: 0;
  }
}

.prices__card--cat .prices__content {
  padding-right: 2em;

  @include screen-max($mobile-width) {
    padding: 0;
  }
}

.prices_list__item {
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $primary-light;
  padding: 0.5em 0;
  font-size: 1.1em;
  flex-wrap: wrap;
  margin-bottom: 1em;

  &:last-of-type {
    border-bottom: unset;
    margin-bottom: 0;
    padding: 0;
  }
}

.prices_list__text {
  font-weight: 500;
}

.prices_details {
  width: 100%;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.prices_details__title {
  font-size: 1.4em;
  color: $primary;
  margin-bottom: 0.5em;
}

.prices_details__item {
  list-style: "• ";
  list-style-position: inside;
  margin-bottom: 0.2em;
}

.prices__card--dog .prices_details__item {
  font-size: 1.2em;
  @include screen-max($mobile-width) {
    font-size: 1.1em;
  }
}

.prices__price {
  text-align: center;
  font-weight: 500;
  font-size: 1.6em;
  color: $primary;
}

.prices__note {
  text-align: center;
  line-height: 1.4;
  font-size: 0.95em;

  @include screen-max($custom-width) {
    font-size: 0.9em;
  }
}

.gallery {
  padding-top: 5em;
  padding-bottom: 7em;

  @include screen-max($custom-width) {
    padding-left: 2em;
    padding-right: 2em;
  }

  &__container {
    width: $custom-width;
    margin: 0 auto;

    @include screen-max($custom-width) {
      width: 100%;
    }
  }

  &__title {
    color: $primary;
    font-weight: 400;
    font-size: 2em;
    text-align: center;
  }

  &__tablist {
    display: flex;
    justify-content: center;
    margin: 1em;
  }

  &__tabitem {
    list-style: none;
    padding: 0.5em 1.5em;
    background: $primary;
    color: $primary-light;
    border-radius: 5px;

    &:first-of-type {
      margin-right: 2em;
    }

    &:focus-visible {
      outline: 0;
    }

    &--active,
    &:hover,
    &:active {
      color: $primary;
      background-color: $primary-light;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__tabpanel {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
  }

  &__image {
    max-height: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 32%;

    @include screen-max($custom-width) {
      flex-basis: 48%;
      max-height: 400px;
    }

    @include screen-max($mobile-width) {
      flex-basis: 100%;
      max-height: 500px;
    }
  }

  .img_thumbnail {
    height: auto;
    width: 100%;

    &:hover,
    &:active {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .lightbox {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 100%;

    .lightbox__content {
      background: rgba(0, 0, 0, 0.7);
      position: relative;
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 5em;

      @include screen-max($custom-width) {
        padding: 3em;
      }
      @include screen-max($mobile-width) {
        padding: 2em 0;
      }
    }

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.contact {
  background-color: $primary;
  position: relative;

  @include screen-max($custom-width) {
    padding: 2em;
  }
}

.contact__container {
  width: $custom-width;
  margin: 0 auto;
  display: flex;
  padding: 3em 0;

  @include screen-max($custom-width) {
    width: 100%;
    flex-direction: column;
  }
}

.contact_form {
  flex-basis: 50%;

  @include screen-max($custom-width) {
    order: 1;
  }
}

.contact_form__input {
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
}

.contact_form__input > label {
  font-weight: 600;
  font-size: 1.1em;
  color: $primary-light;
  margin-bottom: 0.5em;
  cursor: text;
}

.contact_form__input > input:not(.contact_form__submit),
.contact_form__input > textarea {
  font-size: 1em;
  padding: 1em;
  width: 100%;
  border-radius: 4px;
  border: 1px solid $black;
  outline: unset;
}

.contact_form__input > input:not(.contact_form__submit):focus,
.contact_form__input > textarea:focus {
  border-color: $primary;
}

.contact_form__submit {
  margin-top: 2em;
  font-size: 1.3em;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding: 1em;
  background-color: $primary;
  color: $primary-light;
  border: 3px solid $primary-light;
  border-radius: 50px;
  transition-property: color, background-color;
  transition-duration: 0.2s;
}

.contact_form__submit:hover ,
.contact_form__submit:active {
  /* opacity: 0.85; */
  cursor: pointer;
  background-color: $primary-light;
  color: $primary;
}

.contact__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
  padding-left: 2em;

  @include screen-max($custom-width) {
    padding-left: unset;
  }

  .contact__title {
    font-size: 2.6em;
    padding: 0;
    margin-bottom: 1em;
    color: $primary-light;

    @include screen-max($mobile-width) {
      font-size: 2.3em;
    }
  }

  .contact__text {
    font-size: 1.2em;
    margin-bottom: 2em;
    color: $primary-light;
    text-align: center;

    @include screen-max($custom-width) {
      margin-bottom: unset;
    }

    &--sub {
      @include screen-max($custom-width) {
        display: none;
      }
    }
  }

  .contact__link {
    display: flex;
    justify-content: center;
    width: 300px;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 500;
    padding: 1em;
    border-radius: 50px;
    margin-bottom: 1em;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.2);

    @include screen-max($custom-width) {
      width: 100%;
    }

    & svg {
      margin-right: 10px;
      font-size: 1.3em;
    }

    &:hover,
    &:active {
      opacity: 0.7;
      transition: opacity 0.2s;
    }

    &--phone {
      background-color: $primary-light;
      color: $primary;
      transition: opacity 0.2s;
      margin-top: 3em;

      @include screen-max($custom-width) {
        margin-top: 2em;
      }
    }

    &--instagram {
      background-color: #e4405f;
      color: white;
      transition: opacity 0.2s;
    }

    &--facebook {
      background-color: #4267b2;
      color: white;
      transition: opacity 0.2s;

      @include screen-max($custom-width) {
        margin-bottom: 2em;
      }
    }
  }
}

.footer {
  width: 100%;
  padding: 3em 0;
  display: flex;
  justify-content: center;
  background-color: $primary;
  color: $primary-light;

  @include screen-max($mobile-width) {
    flex-direction: column;
    align-items: center;
  }
}

.footer__rights {
  margin-left: 5px;
}

.shape_divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg) translateY(100%);
}

.shape_divider svg {
  position: relative;
  display: block;
  width: 100%;
  height: 80px;

  @include screen-max($mobile-width) {
    height: 50px;
  }
}

.shape_divider .shape-fill {
  fill: #552ac2;
} /*# sourceMappingURL=global.css.map */
