@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
@-webkit-keyframes slideIn {
  from {
    left: -300px;
  }
  to {
    left: 0;
  }
}
@keyframes slideIn {
  from {
    left: -300px;
  }
  to {
    left: 0;
  }
}
* {
  margin: unset;
  padding: unset;
  box-sizing: border-box;
  line-height: 1.2;
}

input {
  font-family: inherit;
}

html {
  height: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

body {
  font-size: 17px;
  font-family: "Fira Sans", sans-serif;
  background-image: url("../images/pattern.png");
}

.header {
  width: 100%;
  background-color: white;
  position: relative;
  z-index: 1;
}
@media (max-width: 1023px) {
  .header {
    background: none;
    position: fixed;
    padding: 1em;
  }
}

.navbar--hamburger {
  display: none;
}
@media (max-width: 1023px) {
  .navbar--hamburger {
    display: block;
  }
}
.navbar--hamburger .navbar__button {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: unset;
  background-color: rgba(28, 21, 23, 0.9);
  color: #d6caf1;
  position: absolute;
  right: 1.5em;
  top: 1.5em;
}
.navbar--hamburger .navbar__button > svg {
  font-size: 1.6em;
}
.navbar--hamburger .navbar__button:hover, .navbar--hamburger .navbar__button:active {
  cursor: pointer;
  opacity: 0.8;
}
.navbar--hamburger .navbar__logo {
  width: 80px;
  height: 80px;
  transition-property: opacity, transform;
  transition-duration: 0.3s;
}
.navbar--hamburger .navbar__logo:hover, .navbar--hamburger .navbar__logo:active {
  opacity: 0.9;
  transform: scale(1.1);
  cursor: pointer;
}
.navbar--hamburger .navbar__logo__image {
  width: 100%;
}
.navbar--hamburger .navbar__list {
  position: fixed;
  background-color: rgba(28, 21, 23, 0.9);
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 260px;
  padding-top: calc(90px + 2em);
}
.navbar--hamburger .navbar__list--show {
  width: 100%;
  -webkit-animation: slideIn 0.3s;
          animation: slideIn 0.3s;
}
.navbar--hamburger .navbar__item {
  list-style: none;
}
.navbar--hamburger .navbar__link {
  display: block;
  padding: 1.5em 2em;
  color: white;
}
.navbar--hamburger .navbar__link--active, .navbar--hamburger .navbar__link:hover, .navbar--hamburger .navbar__link:active {
  cursor: pointer;
  background: #1c1517;
}

.navbar {
  max-width: 1024px;
  height: 200px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 1023px) {
  .navbar {
    display: none;
  }
}
.navbar .navbar__list {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.navbar .navbar__logo {
  margin-right: 20px;
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateZ(0) perspective(1px);
}
.navbar .navbar__logo:hover,
.navbar .navbar__logo:active {
  opacity: 0.8;
  transform: translateZ(0) scale(1.05);
}
.navbar .navbar__logo__image {
  max-width: 120px;
}
.navbar .navbar__item {
  text-decoration: none;
  list-style: none;
  text-align: center;
  margin-right: 2em;
}
.navbar .navbar__item:not(.navbar__logo) {
  width: 100px;
}
.navbar .navbar__link {
  display: block;
  color: #9695b3;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5em 0;
}
.navbar .navbar__link:hover {
  cursor: pointer;
}
.navbar .navbar__link:not(.navbar__logo):hover,
.navbar .navbar__item--active .navbar__link {
  border-bottom: 3px solid #552ac2;
  margin-bottom: -3px;
}

.navbar__socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: absolute;
  right: 0;
}
.navbar__socials .navbar__socials__item {
  list-style: none;
  margin-bottom: 1em;
}
.navbar__socials .navbar__socials__link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50px;
  color: white;
  transition: opacity 0.2s;
}
.navbar__socials .navbar__socials__link:hover {
  opacity: 0.8;
  transition: opacity 0.2s;
}
.navbar__socials .navbar__socials__link--instagram {
  background-color: #e4405f;
  font-size: 1.1em;
  width: 40px;
  height: 40px;
  margin-right: 1em;
}
.navbar__socials .navbar__socials__link--facebook {
  background-color: #4267b2;
  width: 40px;
  height: 40px;
}
.navbar__socials .lang-select__wrapper {
  display: flex;
  flex-grow: 100;
  flex-basis: 100%;
  justify-content: flex-end;
}
.navbar__socials .lang-select__button {
  font-size: 17px;
  border: none;
  outline: none;
  color: black;
  padding: 0.5em;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
.navbar__socials .lang-select__button:hover {
  cursor: default;
}
.navbar__socials .dropdown__content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.navbar__socials .lang-select__dropdown .dropdown__content label {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.navbar__socials .lang-select__dropdown .dropdown__content label:hover {
  cursor: pointer;
  background: #eee;
}
.navbar__socials .lang-select__dropdown .dropdown__content label > * {
  display: none;
}
.navbar__socials .lang-select__dropdown:hover .dropdown__content,
.navbar__socials .lang-select__dropdown:active .dropdown__content {
  display: block;
}

.navbar--hamburger .navbar__socials {
  position: relative;
  justify-content: unset;
  margin: 0 1em;
  margin-top: 2em;
}
.navbar--hamburger .navbar__socials .lang-select__wrapper {
  flex-basis: auto;
  justify-content: flex-end;
}
.navbar--hamburger .navbar__socials .lang-select__button {
  color: #fff;
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.homepage {
  background-image: url("../images/banner.jpg");
  background-position: center;
  background-size: cover;
  height: calc(100vh - 200px);
  width: 100%;
  position: relative;
}
@media (max-width: 1023px) {
  .homepage {
    height: 100vh;
    text-align: center;
  }
}

.homepage__container {
  display: flex;
  width: 1024px;
  height: 100%;
  align-items: center;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .homepage__container {
    width: 100%;
  }
}

.homepage__content {
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: 50%;
  text-shadow: 0 1px 10px #1c1517;
}
@media (max-width: 1023px) {
  .homepage__content {
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .homepage__content {
    max-width: 100%;
    padding: 2em;
  }
}

.homepage__title {
  font-size: 3em;
  font-weight: 300;
  margin-bottom: 0.5em;
}
@media (max-width: 768px) {
  .homepage__title {
    font-size: 2.5em;
    margin-bottom: 1em;
  }
}

.homepage__text {
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.4;
}
@media (max-width: 768px) {
  .homepage__text {
    font-size: 1em;
  }
}

.homepage__button {
  max-width: 320px;
  text-align: center;
  display: block;
  background-image: linear-gradient(to right, #552ac2 0%, #531180 51%, #552ac2 100%);
  transition: 0.5s;
  background-size: 200% auto;
  margin-top: 2em;
  padding: 1em 1.4em;
  color: #d8dce7;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.3em;
  border-radius: 50px;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.5);
}
.homepage__button:hover, .homepage__button:active {
  background-position: right center; /* change the direction of the change here */
  cursor: pointer;
}
@media (max-width: 1023px) {
  .homepage__button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5em;
  }
}

.prices {
  padding-top: 5em;
  padding-bottom: 5em;
}
@media (max-width: 1023px) {
  .prices {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.prices__container {
  width: 1024px;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .prices__container {
    width: 100%;
  }
}

.prices__title {
  font-size: 1.8em;
  color: #552ac2;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1em;
}

.prices__card {
  box-shadow: 0px 15px 80px rgba(34, 35, 58, 0.2);
  border-radius: 25px;
  background: #fff;
  position: relative;
  margin: 0 auto;
  padding: 2em;
  display: flex;
}
@media (max-width: 768px) {
  .prices__card {
    flex-direction: column;
  }
}

.prices__card--dog {
  margin-bottom: 3em;
}

.prices__image {
  max-width: 350px;
  max-height: 500px;
  border-radius: 20px;
  box-shadow: 4px 13px 30px 1px rgba(157, 56, 252, 0.2);
  overflow: hidden;
}
@media (max-width: 768px) {
  .prices__image {
    max-height: 300px;
    margin: 0 auto;
  }
}
@media (max-width: 419px) {
  .prices__image {
    max-height: 250px;
    margin: 0 auto;
  }
}

.prices__image img {
  height: auto;
}

.prices__image--dog {
  transform: translateY(-50px);
  width: 130%;
}
@media (max-width: 768px) {
  .prices__image--dog {
    transform: translateY(-90px);
    width: 125%;
  }
}
@media (max-width: 419px) {
  .prices__image--dog {
    transform: translateY(-40px);
  }
}

.prices__image--cat {
  transform: translate(-85px, -90px);
  width: 130%;
}
@media (max-width: 768px) {
  .prices__image--cat {
    transform: translate(-75px, -170px);
  }
}
@media (max-width: 419px) {
  .prices__image--cat {
    transform: translate(-50px, -100px);
  }
}

.prices__card--cat .prices__image {
  order: 1;
}
@media (max-width: 768px) {
  .prices__card--cat .prices__image {
    order: unset;
  }
}

.prices__content {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-between;
  margin-top: 1em;
  gap: 1.5em;
}

.prices__card--dog .prices__content {
  padding-left: 2em;
}
@media (max-width: 768px) {
  .prices__card--dog .prices__content {
    padding: 0;
  }
}

.prices__card--cat .prices__content {
  padding-right: 2em;
}
@media (max-width: 768px) {
  .prices__card--cat .prices__content {
    padding: 0;
  }
}

.prices_list__item {
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b7a4e2;
  padding: 0.5em 0;
  font-size: 1.1em;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.prices_list__item:last-of-type {
  border-bottom: unset;
  margin-bottom: 0;
  padding: 0;
}

.prices_list__text {
  font-weight: 500;
}

.prices_details {
  width: 100%;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.prices_details__title {
  font-size: 1.4em;
  color: #552ac2;
  margin-bottom: 0.5em;
}

.prices_details__item {
  list-style: "• ";
  list-style-position: inside;
  margin-bottom: 0.2em;
}

.prices__card--dog .prices_details__item {
  font-size: 1.2em;
}
@media (max-width: 768px) {
  .prices__card--dog .prices_details__item {
    font-size: 1.1em;
  }
}

.prices__price {
  text-align: center;
  font-weight: 500;
  font-size: 1.6em;
  color: #552ac2;
}

.prices__note {
  text-align: center;
  line-height: 1.4;
  font-size: 0.95em;
}
@media (max-width: 1023px) {
  .prices__note {
    font-size: 0.9em;
  }
}

.gallery {
  padding-top: 5em;
  padding-bottom: 7em;
}
@media (max-width: 1023px) {
  .gallery {
    padding-left: 2em;
    padding-right: 2em;
  }
}
.gallery__container {
  width: 1024px;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .gallery__container {
    width: 100%;
  }
}
.gallery__title {
  color: #552ac2;
  font-weight: 400;
  font-size: 2em;
  text-align: center;
}
.gallery__tablist {
  display: flex;
  justify-content: center;
  margin: 1em 0;
}
.gallery__tabitem {
  list-style: none;
  padding: 0.5em 1.5em;
  background: #552ac2;
  color: #b7a4e2;
  border-radius: 5px;
}
.gallery__tabitem:first-of-type {
  margin-right: 2em;
}
.gallery__tabitem:focus-visible {
  outline: 0;
}
.gallery__tabitem--active, .gallery__tabitem:hover, .gallery__tabitem:active {
  color: #552ac2;
  background-color: #b7a4e2;
}
.gallery__tabitem:hover {
  cursor: pointer;
}
.gallery__tabpanel {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
}
.gallery__image {
  max-height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 32%;
}
@media (max-width: 1023px) {
  .gallery__image {
    flex-basis: 48%;
    max-height: 400px;
  }
}
@media (max-width: 768px) {
  .gallery__image {
    flex-basis: 100%;
    max-height: 500px;
  }
}
.gallery .img_thumbnail {
  height: auto;
  width: 100%;
}
.gallery .img_thumbnail:hover, .gallery .img_thumbnail:active {
  opacity: 0.6;
  cursor: pointer;
}
.gallery .lightbox {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
}
.gallery .lightbox .lightbox__content {
  background: rgba(0, 0, 0, 0.7);
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 5em;
}
@media (max-width: 1023px) {
  .gallery .lightbox .lightbox__content {
    padding: 3em;
  }
}
@media (max-width: 768px) {
  .gallery .lightbox .lightbox__content {
    padding: 2em 0;
  }
}
.gallery .lightbox img {
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.contact {
  background-color: #552ac2;
  position: relative;
}
@media (max-width: 1023px) {
  .contact {
    padding: 2em;
  }
}

.contact__container {
  width: 1024px;
  margin: 0 auto;
  display: flex;
  padding: 3em 0;
}
@media (max-width: 1023px) {
  .contact__container {
    width: 100%;
    flex-direction: column;
  }
}

.contact_form {
  flex-basis: 50%;
}
@media (max-width: 1023px) {
  .contact_form {
    order: 1;
  }
}

.contact_form__input {
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
}

.contact_form__input > label {
  font-weight: 600;
  font-size: 1.1em;
  color: #b7a4e2;
  margin-bottom: 0.5em;
  cursor: text;
}

.contact_form__input > input:not(.contact_form__submit),
.contact_form__input > textarea {
  font-size: 1em;
  padding: 1em;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #1c1517;
  outline: unset;
}

.contact_form__input > input:not(.contact_form__submit):focus,
.contact_form__input > textarea:focus {
  border-color: #552ac2;
}

.contact_form__submit {
  margin-top: 2em;
  font-size: 1.3em;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding: 1em;
  background-color: #552ac2;
  color: #b7a4e2;
  border: 3px solid #b7a4e2;
  border-radius: 50px;
  transition-property: color, background-color;
  transition-duration: 0.2s;
}

.contact_form__submit:hover,
.contact_form__submit:active {
  /* opacity: 0.85; */
  cursor: pointer;
  background-color: #b7a4e2;
  color: #552ac2;
}

.contact__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
  padding-left: 2em;
}
@media (max-width: 1023px) {
  .contact__content {
    padding-left: unset;
  }
}
.contact__content .contact__title {
  font-size: 2.6em;
  padding: 0;
  margin-bottom: 1em;
  color: #b7a4e2;
}
@media (max-width: 768px) {
  .contact__content .contact__title {
    font-size: 2.3em;
  }
}
.contact__content .contact__text {
  font-size: 1.2em;
  margin-bottom: 2em;
  color: #b7a4e2;
  text-align: center;
}
@media (max-width: 1023px) {
  .contact__content .contact__text {
    margin-bottom: unset;
  }
}
@media (max-width: 1023px) {
  .contact__content .contact__text--sub {
    display: none;
  }
}
.contact__content .contact__link {
  display: flex;
  justify-content: center;
  width: 300px;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 500;
  padding: 1em;
  border-radius: 50px;
  margin-bottom: 1em;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1023px) {
  .contact__content .contact__link {
    width: 100%;
  }
}
.contact__content .contact__link svg {
  margin-right: 10px;
  font-size: 1.3em;
}
.contact__content .contact__link:hover, .contact__content .contact__link:active {
  opacity: 0.7;
  transition: opacity 0.2s;
}
.contact__content .contact__link--phone {
  background-color: #b7a4e2;
  color: #552ac2;
  transition: opacity 0.2s;
  margin-top: 3em;
}
@media (max-width: 1023px) {
  .contact__content .contact__link--phone {
    margin-top: 2em;
  }
}
.contact__content .contact__link--instagram {
  background-color: #e4405f;
  color: white;
  transition: opacity 0.2s;
}
.contact__content .contact__link--facebook {
  background-color: #4267b2;
  color: white;
  transition: opacity 0.2s;
}
@media (max-width: 1023px) {
  .contact__content .contact__link--facebook {
    margin-bottom: 2em;
  }
}

.footer {
  width: 100%;
  padding: 3em 0;
  display: flex;
  justify-content: center;
  background-color: #552ac2;
  color: #b7a4e2;
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
}

.footer__rights {
  margin-left: 5px;
}

.shape_divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg) translateY(100%);
}

.shape_divider svg {
  position: relative;
  display: block;
  width: 100%;
  height: 80px;
}
@media (max-width: 768px) {
  .shape_divider svg {
    height: 50px;
  }
}

.shape_divider .shape-fill {
  fill: #552ac2;
} /*# sourceMappingURL=global.css.map *//*# sourceMappingURL=global.css.map */